import { triggerToast } from "./index";

interface NotificationProps {
  title: string;
  summary?: string;
}

export const failNotification = ({ title, summary }: NotificationProps) => {
  triggerToast({
    message: { summary, title },
    variant: "danger",
  });
};

export const successNotification = ({ title, summary }: NotificationProps) => {
  triggerToast({
    message: { summary, title },
    variant: "success",
  });
};

export const warningNotification = ({ title, summary }: NotificationProps) => {
  triggerToast({
    message: { summary, title },
    variant: "warning",
  });
};
