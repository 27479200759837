import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import { Integrations } from "@sentry/tracing";
import mixpanel from "mixpanel-browser";

const mixpanel_token = process.env.REACT_APP_MIXPANEL_TOKEN;
const build_env = process.env.REACT_APP_ENV;

if (mixpanel_token && build_env !== "development") {
  mixpanel.init(mixpanel_token, { ignore_dnt: true });
}

Sentry.init({
  dsn: "https://a22425e1044a459484256e08d2b7a803@o286634.ingest.sentry.io/4505466726318080",
  integrations: [new Integrations.BrowserTracing()],
  release: process.env.REACT_APP_ENV,
  environment: process.env.REACT_APP_ENV,
  enabled: true,
  tracesSampleRate: process.env.REACT_APP_ENV !== "production" ? 1.0 : 0.5,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
