import React, { ReactElement } from "react";
import { forEach } from "lodash";
import classNames from "classnames";
import { getElement } from "utils/getElement";
import { DEVICE_TYPE } from "utils/constant";
import { customBlocksTypes } from "components/Tools/CustomBlocks/utils";
import { schema } from "components/BlockNoteEditor";
import {
  ContentItem,
  DeviceType,
  FieldData,
  HtmlBlock,
  LogicProps,
} from "../types";
import { postFormFile } from "./formDataHelpers";
import { applyConditionalLogic } from "./conditionalLogic";

export const onChangeInBlock = (
  type: string,
  res: any,
  attrkey: string,
  setFormFileData: (val: FieldData) => void,
  setFormData: (val: FieldData) => void,
  logicsRef: React.MutableRefObject<LogicProps[]>,
) => {
  if (type === customBlocksTypes.file) {
    postFormFile(res, attrkey, setFormFileData);
  } else {
    setFormData({ [attrkey]: res });
    applyConditionalLogic(logicsRef.current, attrkey, res?.value);
  }
};

export const initBlocksRender = async (
  content: Array<Record<string, any>>,
  setFormFileData: (val: FieldData) => void,
  setFormData: (val: FieldData) => void,
  editor: typeof schema.BlockNoteEditor,
  logicsRef: React.MutableRefObject<LogicProps[]>,
) => {
  const groupedBlocks: any[] = [];

  let groupedBlockProps = {};
  let htmlBlocks: HtmlBlock[] = [];
  let layout: number[] = [];
  const conditionalLogics = [];

  for (const item of content) {
    const { type, props, id } = (item as ContentItem) || {};
    let blockElement = null;

    // if logic block store logics and continue loop
    if (type === customBlocksTypes.logic) {
      conditionalLogics.push(props);
      continue;
    }

    // if sectionGroup block store existing group, initialize htmlBlocks, layout, and continue loop
    if (type === customBlocksTypes.sectionStart) {
      htmlBlocks.push(React.cloneElement(getElement(type), { ...props }));
      layout.push(1);
      groupedBlocks.push({ htmlBlocks, layout, props: groupedBlockProps });
      [htmlBlocks, layout, groupedBlockProps] = [[], [], props];
      continue;
    }

    // if sectionGroupEnd block store group, initialize htmlBlocks, layout and continue loop
    if (type === customBlocksTypes.sectionEnd) {
      groupedBlocks.push({ htmlBlocks, layout, props: groupedBlockProps });
      [htmlBlocks, layout, groupedBlockProps] = [[], [], {}];
      continue;
    }

    if (typeof type === "string" && type in customBlocksTypes) {
      const { attrkey, label, hide } = props || {};

      const element = React.cloneElement(getElement(type, id), {
        ...props,
        onChange: (res: any) =>
          attrkey &&
          onChangeInBlock(
            type,
            res,
            attrkey,
            setFormFileData,
            setFormData,
            logicsRef,
          ),
      });
      layout.push(props?.layout?.value || 1);
      blockElement = (
        <div
          id={attrkey || label}
          className={classNames(
            hide && "hidden",
            "w-full p-0.5 mb-3",
            props?.rowSpan && `row-span-${props.rowSpan}`,
          )}
        >
          {element}
        </div>
      );
    } else {
      blockElement = await editor?.blocksToFullHTML([item]);
      layout.push(1);
    }
    htmlBlocks.push(blockElement);
  }

  // group the last left out blocks
  htmlBlocks.length > 0 &&
    groupedBlocks.push({ htmlBlocks, layout, props: groupedBlockProps });
  return { groupedBlocks, conditionalLogics };
};

export const getContent = (contentItem: HtmlBlock) => {
  if (typeof contentItem === "string") {
    return (
      <div
        className="bn-default-styles"
        dangerouslySetInnerHTML={{ __html: contentItem }}
      />
    );
  }
  if (React.isValidElement(contentItem)) {
    return <>{contentItem}</>;
  }
  return <></>;
};

export const renderContent = (
  htmlContent: HtmlBlock[],
  blocksLayout: number[],
  deviceType: DeviceType,
) => {
  let currentGridItems: ReactElement[] = [];
  let currentLayoutType = blocksLayout?.[0];

  const grids: ReactElement[] = [];

  const getGridClassNames = (layoutType: number) =>
    classNames(
      "grid gap-4 grid-cols-auto-fit-minmax-200",
      deviceType !== DEVICE_TYPE.MOBILE &&
        (layoutType === 1
          ? "sm:grid-cols-1"
          : layoutType === 2
            ? "sm:grid-cols-2"
            : "sm:grid-cols-3"),
    );

  forEach(htmlContent, (contentItem, index) => {
    const layoutType = blocksLayout[index];
    // If layoutType changes or the current grid is full
    if (layoutType !== currentLayoutType) {
      // Add the current grid to the list of grids
      grids.push(
        <div
          key={`grid-${index}`}
          className={getGridClassNames(currentLayoutType)}
        >
          {currentGridItems}
        </div>,
      );

      // Reset current grid items and update layout type
      currentGridItems = [];
      currentLayoutType = layoutType;
    }

    // Add the contentItem to the current grid
    currentGridItems.push(getContent(contentItem));

    // Handle the last grid which may not be added in the loop
    if (index === htmlContent.length - 1) {
      grids.push(
        <div
          key={`grid-${index}-end`}
          className={getGridClassNames(currentLayoutType)}
        >
          {currentGridItems}
        </div>,
      );
    }
  });

  return grids;
};
