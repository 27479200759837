import { useComponentsContext } from "@blocknote/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

export interface MenuButtonProps {
  onClick: () => void;
  label: string;
  icon: IconDefinition;
}

const MenuButton = ({ onClick, label, icon }: MenuButtonProps) => {
  const Components = useComponentsContext()!;
  return (
    <Components.Generic.Menu.Item onClick={onClick}>
      <>
        <FontAwesomeIcon icon={icon} className="text-gray-600 mr-2" />
        {label && <span>{label}</span>}
      </>
    </Components.Generic.Menu.Item>
  );
};

export default MenuButton;
