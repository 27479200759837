import React from "react";

const SectionGroup = ({
  label,
  textAlignment,
  type,
}: {
  label?: string;
  textAlignment?: string;
  type?: string;
}) => {
  return (
    <div
      className="w-full text-lg font-medium flex"
      style={{ justifyContent: textAlignment }}
    >
      {label || `------ section group ${type ? "ended" : "started"} ------`}
    </div>
  );
};

export default SectionGroup;
