import { memo, useState, useLayoutEffect } from "react";
import classNames from "classnames";
import Input from "../../Tools/Input";
import { DeleteIcon } from "images/DeleteIcon";
import { PlusIcon } from "images/PlusIcon";
import { diff } from "components/SidePanel/utils";
import Toggle from "components/Popover/MenuPopover/ToggleSwitch";

export type InputProps = {
  label?: string;
  value?: string;
  name?: string;
  no_column?: number;
  id?: string;
};

type InputListProps = {
  onChange: (value: InputProps[] | InputProps) => void;
  label: string;
  options: InputProps[];
  isSingle: boolean;
  error?: string | null;
  hasToggle?: boolean;
  isEnabled?: boolean;
  fieldEnableChange?: (e: boolean) => void;
};
const InputList = ({
  onChange,
  options,
  label,
  isSingle,
  error = "",
  hasToggle,
  isEnabled,
  fieldEnableChange,
}: InputListProps) => {
  const [list, setList] = useState<InputProps[]>([
    {
      label: "",
      value: "",
      name: "",
    },
  ]);
  const [isToggled, setIsToggled] = useState(isEnabled || false);

  useLayoutEffect(() => {
    if (options?.length > 0) {
      setList(options);
    }
  }, [options]);

  useLayoutEffect(() => {
    if (typeof isEnabled === "boolean") setIsToggled(isEnabled);
  }, [isEnabled]);

  const updateListValue = (obj: InputProps, index: number) => {
    const inputList = list.map((list) => ({
      label: list?.label,
      name: list?.name,
      id: list?.id,
      value: list?.value,
    }));

    if (typeof obj.value === "string") {
      inputList[index].value = obj.value.trim();
      inputList[index].id = obj.value.trim();
    }
    if (typeof obj.label === "string") {
      inputList[index].label = obj.label.trim();
      inputList[index].name = obj.label.replace(" ", "");
    }
    setList([...inputList]);

    if (inputList[index].label && inputList[index].value) {
      if (isSingle) onChange(inputList[0]);
      else onChange(inputList);
    }
  };

  const addInputToList = () => {
    setList([...list, { label: "", value: "" }]);
  };

  const deleteInput = (index: number) => {
    const inputList = [...list];
    inputList.splice(index, 1);

    onChange && onChange(inputList);
    setList(inputList);
  };

  return (
    <div className="flex-col space-y-2 w-full">
      <div className="flex justify-between">
        <label
          htmlFor="label"
          className={classNames(
            "h-5",
            hasToggle ? "text-sm font-normal" : "text-lg font-semibold",
          )}
        >
          {label}
        </label>
        {hasToggle && (
          <Toggle
            checked={isEnabled}
            onChange={(e: boolean) => {
              setIsToggled(e);
              fieldEnableChange?.(e);
            }}
          />
        )}
      </div>
      {(!hasToggle || (hasToggle && isToggled)) && (
        <>
          <div className="flex items-center mt-8 mb-4">
            <label
              htmlFor="label"
              className="ml-2 mr-2.5 flex text-sm w-44 h-4"
            >
              Label
            </label>
            <label htmlFor="value" className=" flex text-sm">
              Value
            </label>
          </div>
          {list.map((input: InputProps, index: number) => (
            <div
              className="flex items-center mb-3 gap-1"
              key={label + index}
              data-testid="inputlist_items"
            >
              <div className="">
                <Input
                  data-testid="inputlist_label"
                  aria-label="label"
                  value={input?.label}
                  onChange={(label: string) => {
                    if (
                      diff(input?.label ?? "", input?.value ?? "")?.length ===
                        0 &&
                      !isSingle
                    ) {
                      updateListValue({ label, value: label }, index);
                    } else updateListValue({ label }, index);
                  }}
                />
              </div>
              <div className="">
                <Input
                  data-testid="inputlist_input"
                  aria-label="value"
                  value={input?.value}
                  onChange={(value: string) =>
                    updateListValue({ value }, index)
                  }
                />
              </div>
              {!isSingle && (
                <div
                  data-testid="inputlist_delete"
                  className={`cursor-pointer flex items-center ${
                    list.length <= 1 ? "opacity-25 cursor-not-allowed" : ""
                  }`}
                  onClick={() => list.length > 1 && deleteInput(index)}
                >
                  <DeleteIcon />
                </div>
              )}
            </div>
          ))}
          {error && <div className="text-xs text-rose-800 ml-2">{error}</div>}
          {!isSingle && (
            <div
              className="cursor-pointer text-sm flex text-blue-700 ml-2 items-center gap-1.5 font-medium"
              onClick={addInputToList}
              data-testid="inputlist_insert"
            >
              <PlusIcon />
              Add another
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(InputList);
