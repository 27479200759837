/**
 *
 * Input
 *
 */

import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import classNames from "classnames";
import { find, round, get } from "lodash";
import { AddImage } from "images/AddImage";
import { DeleteIcon } from "images/DeleteIcon";
import { RootState } from "store";
import {
  useUploadFileMutation,
  // useGetFileDetailsQuery,
} from "store/services/updateFormBuilder";
import { uploadFileToS3 } from "store/services/api";
import {
  updateFaceAuthDetails,
  updateFileUploadStatus,
  updateFormFileData,
} from "store/actions";
import {
  detectFace,
  onDetectionSuccess,
  readFileData,
  resizeImage,
} from "utils";
import { getItem } from "utils/store";
import {
  ERROR_MESSAGES,
  FACE_DETECTION_MESSAGES,
  UPLOAD_STATUS,
} from "utils/constant";
import Tooltip from "components/Tooltip";
import { FileOptions } from "components/Block/blockMenuList";
import MarkdownWrapper from "components/MarkdownWrapper";
import Loader from "components/Loader";
import {
  failFileUploadNotification,
  successFileUploadNotification,
} from "components/Notifications/ToastNotification";
// import { FileDownloadFileData } from "containers/BlockNoteRenderer/types";

export const File = (props: any) => {
  const {
    required,
    label,
    name,
    disabled,
    updatable,
    pre_filled,
    fileTypes,
    size,
    error,
    onChange,
    readOnly,
    tooltip,
    attrkey,
    value,
    faceAuth,
    faceDetect,
    preview,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const { candidateDetails, faceAuthDetails, fileUploadStatus } =
    useSelector((state: RootState) => state?.form) || {};
  const { user_id } = getItem("user_details") || {};
  const inputRef = useRef<any>(null);
  const [fileSelected, setFileSelected] = useState<any>(null);
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [faceDetectedMessage, setFaceDetectedMessage] = React.useState("");

  const uploadStatus = get(fileUploadStatus, attrkey ?? "");

  const [uploadFile] = useUploadFileMutation();
  // const { data: file_download_file } = useGetFileDetailsQuery<{
  //   data: FileDownloadFileData | undefined;
  // }>(
  //   {
  //     file_id: value,
  //   },
  //   {
  //     skip: typeof value !== "number",
  //   },
  // );

  const setUploadStatus = (status: any) =>
    attrkey && dispatch(updateFileUploadStatus({ [attrkey]: status }));

  const onFileUploadSuccess = (fileId: number) => {
    const file = get(inputRef.current, "files.0");
    const dataURL = get(inputRef.current, "dataURL");
    setUploadStatus(UPLOAD_STATUS.SUCCESS);
    onChange?.({ file, fileId });
    successFileUploadNotification();
    if (faceAuth && attrkey && candidateDetails?.id && dataURL) {
      dispatch(
        updateFaceAuthDetails({
          [attrkey]: {
            file: dataURL,
            file_name: file?.name,
            candidate_id: candidateDetails?.id,
          },
        }),
      );
    }
  };

  const onFileUploadFailure = () => {
    setUploadStatus(UPLOAD_STATUS.FAILED);
    inputRef.current.value = "";
    failFileUploadNotification();
  };

  const uploadCallback = (res: any) => {
    const { id, presigned_url } = get(res, "data.file_upload_file", {});
    const file = get(inputRef.current, "files.0");

    if (!presigned_url || !file) onFileUploadFailure();
    else
      uploadFileToS3(
        presigned_url,
        file,
        id,
        onFileUploadSuccess,
        onFileUploadFailure,
      );
  };

  const onFileSelect = (e: any) => {
    const file = get(e, "target.files.0");

    if (!file) return;
    const fileName = get(file, "name");
    const fileType = fileName?.split(".")?.pop();

    const handleFileUpload = () => {
      setUploadStatus(UPLOAD_STATUS.UPLOADING);
      setFileSelected({
        fileName,
        fileType,
        fileSize: round(get(file, "size", 0) / (1024 * 1024), 2) || 0,
      });
      uploadFile({ fileName, fileType, user_id, uploadCallback });
    };

    readFileData(file, (dataURL) => {
      inputRef.current.dataURL = dataURL;

      // faceDetection action
      if (faceDetect)
        detectFace(
          `${dataURL}`,
          (detections: any) => {
            const { isFaceDetected, message } = onDetectionSuccess(detections);
            setFaceDetectedMessage(message);
            if (isFaceDetected) handleFileUpload();
          },
          () => setFaceDetectedMessage(ERROR_MESSAGES.UNKNOWN),
        );
      else handleFileUpload();

      // preview action
      if (preview) {
        const image = new Image();
        image.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          resizeImage(canvas, ctx, image);
          setPreviewImage(canvas.toDataURL("image/png"));
        };
        image.src = `${dataURL}`;
      }
    });
  };

  const onClearFile = (e: any) => {
    e.stopPropagation();
    setFileSelected(null);
    inputRef.current.value = "";
    dispatch(updateFormFileData({ [attrkey]: null }));
    setPreviewImage(null);
    setFaceDetectedMessage("");
  };

  const getFileTrailingIcon = () => {
    switch (uploadStatus) {
      case UPLOAD_STATUS.UPLOADING:
        return <Loader />;
      case UPLOAD_STATUS.SUCCESS:
        return <DeleteIcon />;
      case UPLOAD_STATUS.FAILED:
        return (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="text-red-400"
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    inputRef.current?.setCustomValidity(
      required && (!fileSelected || uploadStatus !== UPLOAD_STATUS.SUCCESS)
        ? `This is a required field`
        : fileSelected?.fileSize > parseFloat(size)
          ? `Exceeds file size of ${size} MB`
          : "",
    );
  }, [fileSelected, uploadStatus]);

  // useEffect(() => {
  //   if (file_download_file) {
  //     const { id, original_filename, resource_url } = file_download_file || {};
  //     setFileSelected({
  //       id,
  //       fileName: original_filename,
  //       resource_url,
  //       fileType: original_filename?.split(".")?.pop(),
  //     });
  //     setUploadStatus(UPLOAD_STATUS.SUCCESS);
  //   }
  // }, [file_download_file]);

  const onFileClickFn = () => {
    if (
      !disabled &&
      !readOnly &&
      !(uploadStatus === UPLOAD_STATUS.UPLOADING) &&
      !(!updatable && pre_filled)
    )
      inputRef.current.click();
  };

  const fileTypeList = preview
    ? "JPG, JPEG, PNG"
    : (fileTypes?.length > 0 ? fileTypes : FileOptions)
        .map((type: any) => type.name)
        .join(", ");

  const authError = get(faceAuthDetails, `${attrkey}.error`);

  return (
    <div className="gap-8 cursor-pointer bg-white">
      <div className="flex">
        {label ? (
          <label
            htmlFor={name}
            className={`flex items-center text-sm mb-2 font-normal text-stone-900 not-italic
              ${(disabled || (!updatable && pre_filled)) && "group group-hover:cursor-pointer"}
            `}
            data-testid="input-label"
          >
            <MarkdownWrapper styles={required && "required-label"}>
              {label}
            </MarkdownWrapper>
            {tooltip && <Tooltip tooltipId={label} content={tooltip} />}
          </label>
        ) : (
          <div />
        )}
        <input
          {...rest}
          ref={inputRef}
          type="file"
          accept={
            preview
              ? ".png , .jpeg , .jpg"
              : fileTypes?.map((type: any) => type.ext.join(",")).join(",")
          }
          name={name}
          onChange={onFileSelect}
          id={name}
          className="opacity-0 pointer-events-none w-0.5"
          aria-describedby={`${name}-error`}
          data-testid={rest["data-testid"] || "input"}
          role="button"
        />
      </div>
      {previewImage && (
        <img src={previewImage} alt="preview" className="max-h-48" />
      )}
      {(authError || faceDetectedMessage) && (
        <div
          className={classNames(
            "my-1 text-sm",
            faceDetectedMessage === FACE_DETECTION_MESSAGES.FACE_DETECTED &&
              !authError
              ? "text-green-500"
              : "text-red-500",
          )}
        >
          {authError ?? faceDetectedMessage}
        </div>
      )}
      <div
        className={classNames(
          "flex gap-3 rounded-md shadow-sm w-full border-2 border-dashed p-2 max-w-[25rem]",
          (disabled || (!updatable && pre_filled)) && "opacity-50",
        )}
        onClick={() => onFileClickFn()}
      >
        {!fileSelected?.resource_url && <AddImage />}
        <div className="flex flex-col justify-center gap-1">
          <div className="group-cursor-pointer justify-start items-center gap-1 inline-flex cursor-pointer">
            {fileSelected ? (
              <div className="flex gap-2 text-gray-600 text-sm font-medium leading-tight">
                <div className="truncate max-w-56">
                  {fileSelected?.fileName || "--"}
                </div>
                <div data-testid="cancel_button" onClick={onClearFile}>
                  {getFileTrailingIcon()}
                </div>
              </div>
            ) : (
              <div className="text-sm font-medium leading-tight text-blue-600">
                Upload a file
              </div>
            )}
          </div>
          <div className="text-left text-gray-500 text-xs font-normal leading-none">
            {!fileSelected ? (
              <div
                className={"w-60 text-gray-500 text-xs font-normal"}
              >{`${fileTypeList} ${size ? `up to ${size} MB` : ""}`}</div>
            ) : (
              `${
                find(FileOptions, (t) => t.ext.includes(fileSelected?.fileType))
                  ?.name
              } ${fileSelected?.fileSize || "--"} MB`
            )}
          </div>
        </div>
      </div>
      {error && (
        <p
          className="font-normal text-xs text-rose-800 mt-2"
          id={`${name}-description`}
          data-testid="input_error_msg"
        >
          {error}
        </p>
      )}
    </div>
  );
};

File.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.number,
  fileTypes: PropTypes.array,
  updatable: PropTypes.bool,
};

export default memo(File);
