import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
} from "react";
import { find } from "lodash";
import classNames from "classnames";
import { PropertyBoxType } from "components/Block/blockMenuList";
import { resetFieldsEffect } from "components/SidePanel/effects";
import { getInputComponent } from "components/SidePanel/inputComponents";
import { getFieldProps } from "components/SidePanel/utils";
import { onInputComponentChange } from "components/SidePanel/blockValidators";
import { Field } from "components/SidePanel";

export interface MenuBoxProps {
  propertyBox: Field[];
  setProperties?: (obj: object) => void;
  validations: object;
  block?: { type: string; properties: object };
  properties?: object;
  menuFieldsRef?: any;
  selectFieldTypes?: any[];
  selectParentFields?: any[];
}

const MenuBox: React.FC<MenuBoxProps> = ({
  propertyBox,
  validations,
  block,
  menuFieldsRef,
  selectFieldTypes,
  selectParentFields,
}) => {
  const [errorList, setErrorList] = useState<any>({});
  const [fields, setFields] = useState<Field[]>(
    menuFieldsRef?.current || propertyBox,
  );
  const [propertyBoxState, setPropertyBoxState] = useState<any>({
    ...fields,
    validations,
  });

  const hideCustomOptions = useMemo(() => {
    const selectFieldType =
      find(propertyBoxState, { key: "selectFieldType" }) || {};
    return selectFieldType?.value && selectFieldType?.isEnabled;
  }, [propertyBoxState]);

  useLayoutEffect(() => {
    if (!menuFieldsRef?.current)
      resetFieldsEffect(block, setFields, setPropertyBoxState, menuFieldsRef);
  }, []);

  useEffect(() => {
    if (menuFieldsRef) menuFieldsRef.current = fields;
  }, [fields]);

  return (
    <div className="flex flex-col gap-2 p-2 bg-white" data-testid="menubox">
      {fields?.map((field: PropertyBoxType, index: number) => (
        <div
          key={"box.type" + index}
          data-testid="menubox-element"
          className={classNames(
            hideCustomOptions && field?.name === "Options" && "hidden",
          )}
        >
          {React.cloneElement(
            getInputComponent(
              field,
              propertyBoxState,
              setPropertyBoxState,
              errorList,
              setErrorList,
            ),
            {
              ...getFieldProps(field, propertyBoxState),
              onChange: (value: string) =>
                onInputComponentChange(
                  field,
                  value,
                  propertyBoxState,
                  errorList,
                  setErrorList,
                  setPropertyBoxState,
                ),
              fieldEnableChange: (value: boolean) => {
                const newPropertyBoxState = { ...propertyBoxState };
                Object.values(newPropertyBoxState).map((box: any) => {
                  let newBox = box;
                  if (box && box?.key === field?.key) {
                    newBox.isEnabled = value;
                  }
                  return newBox;
                });
                setPropertyBoxState(newPropertyBoxState);
              },
              ...(field?.key === "selectFieldType" && {
                options: selectFieldTypes || [],
              }),
              ...(field?.key === "selectParentField" && {
                options: selectParentFields || [],
              }),
            },
          )}
        </div>
      ))}
    </div>
  );
};
export default memo(MenuBox);
