import { createAction } from "@reduxjs/toolkit";
import { CandidateData, FieldData } from "containers/BlockNoteRenderer/types";
import { FormStateType } from "Pages/Editor";
import { UpdateFormDataPayload } from "store/types";
import {
  UPDATE_FORM_DATA,
  UPDATE_FORM_FILE_DATA,
  UPDATE_FORM_STATE,
  UPDATE_CANDIDATE_DETAILS,
  UPDATE_FACE_AUTH_DETAILS,
  UPDATE_FILE_UPLOAD_STATUS,
} from "store/constants";

export const updateFormData =
  createAction<UpdateFormDataPayload>(UPDATE_FORM_DATA);

export const updateFormFileData = createAction<FieldData>(
  UPDATE_FORM_FILE_DATA,
);

export const updateFormState = createAction<FormStateType>(UPDATE_FORM_STATE);

export const updateCandidateDetails = createAction<CandidateData>(
  UPDATE_CANDIDATE_DETAILS,
);

export const updateFaceAuthDetails = createAction<FieldData>(
  UPDATE_FACE_AUTH_DETAILS,
);

export const updateFileUploadStatus = createAction<FieldData>(
  UPDATE_FILE_UPLOAD_STATUS,
);
