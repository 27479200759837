import { checkForNumberErrors } from "./setters";
import { errorType } from "components/Block/blockMenuList";

export const diff = (diffMe: string, diffBy: string) =>
  diffMe.replace(diffBy, "");

export const updateProperties = (
  value: any,
  key: string,
  propertyBoxState: any,
  setPropertyBoxState: (obj: any) => any,
  errorList: any,
  setErrorList: (obj: any) => any,
) => {
  // const obj = { ...propertyBoxState };
  // const validations = obj.validations;
  // if (validations[key]) {
  //   const isError = !validations[key](value);
  //   const newErrors = { ...errorList, [key]: isError };
  //   const noFalseErrors = Object.keys(newErrors).filter(
  //     (key: any) => newErrors[key] === true
  //   );
  //   setErrorList(noFalseErrors);
  //   obj[key] = value;
  //   setPropertyBoxState(obj);
  // }
};
export const findIndex = (array: any, key: any) => {
  return Object.values(array).findIndex((item: any) => item?.key === key);
};

export const isValidName = (
  propertyBoxState: any,
  fields: any,
  setFields: any,
) => {
  const nameField =
    propertyBoxState[findIndex(Object.values(propertyBoxState), "attrkey")];
  if (!nameField) return true;
  const newField = checkForNumberErrors(nameField, propertyBoxState);
  if (!newField.value || errorOrNone(fields)) {
    const newField = {
      ...fields[0],
      error: propertyBoxState[0].value ? false : errorType.emptyName,
    };
    const newFields = [newField, ...fields.slice(1)];
    setFields(newFields);
    return false;
  } else {
    return true;
  }
};
export const checkLessThan = (greater: any, lesser: any) =>
  parseInt(greater) >= parseInt(lesser);

export const getBlockName = (type: string) => {
  switch (type) {
    case "text":
      return "Text";
    case "image":
      return "Image";
    case "file":
      return "File";
    case "input":
      return "Textbox";
    case "number":
      return "Number";
    case "datepicker":
      return "Date";
    case "select":
      return "Dropdown";
    case "checkbox":
      return "Checkbox";
    case "radio":
      return "Radio Button Group";
    case "email":
      return "Email";
    case "textarea":
      return "Textarea";
    case "button":
      return "Button";
  }
};

export const isErrorInList = (errorList: any) =>
  Object.values(errorList).includes(true);

export const errorOrNone = (fields: any) =>
  fields
    .map((field: any) => field.error)
    .reduce((acc: any, curr: any) => {
      return acc || curr;
    }, false);

export const applyChangesToFields = (newFields: any, propertyBoxState: any) => {
  newFields.map((field: any) => {
    if (propertyBoxState[field.key]) {
      field.value = propertyBoxState[field.key].value
        ? propertyBoxState[field.key].value
        : propertyBoxState[field.key];
    }
    checkForNumberErrors(field, newFields);
    return field;
  });
  return newFields;
};

export const validateField = (
  field: any,
  value: any,
  propertyBoxState: any,
  errorList: any,
  setErrorList: any,
  setPropertyBoxState: (state: any) => void,
) => {
  const newPropertyBoxState = { ...propertyBoxState };
  Object.values(newPropertyBoxState).map((box: any) => {
    let newBox = box;
    if (box && box.key === field.key) {
      newBox.value = value;
    }
    return newBox;
  });
  if (field?.validations?.length > 0) {
    const errors =
      !value && field.key !== "attrkey"
        ? [false]
        : field.validations.map((validation: any) => !validation(value));
    const isError = errors.find((error: any) => !!error);
    field.error = isError ? field.errormsg : false;
  }

  checkForNumberErrors(field, newPropertyBoxState);
  const newErrorList = { ...errorList, [field.key]: field.error };
  setErrorList(newErrorList);
  setPropertyBoxState(newPropertyBoxState);
};

export const getFieldProps = (props: any, propertyBoxState: any) => {
  if (!props?.error) delete props.error;
  const nameField = Object.values(propertyBoxState)[0] as any;
  if (
    props?.key === "label" &&
    nameField?.value &&
    diff(nameField?.value ?? "", props?.value ?? "").length === 1
  )
    props.value = nameField?.value;
  return {
    ...props,
    type: ["file"].includes(props?.type)
      ? props.type
      : props?.subType || "text",
  };
};

export const getBlockProps = (props: any) => {
  if (!props) return;
  if (!props.error) delete props.error;
  return {
    ...props,
    type: ["file"].includes(props.type) ? props.type : "text",
  };
};
