import { createApi, retry } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";

const rootUrl = process.env.REACT_APP_BASE_URL;

export const authServiceApi = createApi({
  reducerPath: "auth_service",
  baseQuery: retry(
    graphqlRequestBaseQuery({
      url: `${rootUrl}/v1/graphql`,
    }),
    { maxRetries: 2 },
  ),

  endpoints: (builder: any) => ({
    exchangeToken: builder.mutation({
      query: ({ token }: { token: string }) => ({
        document: gql`
          mutation ($token: String!) {
            auth_get_exchange_token(token: $token) {
              access_token
              custom_token
            }
          }
        `,
        variables: {
          token,
        },
      }),
    }),
    getCustomToken: builder.mutation({
      query: ({ hashToken }: { hashToken: string }) => ({
        document: gql`
          mutation ($hashToken: String!) {
            auth_get_custom_token(hash_token: $hashToken) {
              custom_token
            }
          }
        `,
        variables: {
          hashToken,
        },
      }),
    }),
    getAccessToken: builder.mutation({
      query: ({ customToken }: { customToken: string }) => ({
        document: gql`
          mutation ($customToken: String!) {
            auth_get_access_token(data: { custom_token: $customToken }) {
              data {
                access_token
              }
            }
          }
        `,
        variables: {
          customToken,
        },
      }),
    }),
  }),
});

export const {
  useExchangeTokenMutation,
  useGetCustomTokenMutation,
  useGetAccessTokenMutation,
} = authServiceApi;
