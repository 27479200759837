/**
 *
 * Notification
 *
 */

import React, { memo } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Content from "./Content";
import "react-toastify/dist/ReactToastify.css";

export function Notification(props: any) {
  const { children, variant, message, actions, closeToast } = props;
  return (
    <>
      {children || (
        <Content
          title={message?.title}
          summary={message?.summary}
          close={closeToast}
          actions={actions}
          variant={variant}
        />
      )}
    </>
  );
}

Notification.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    "success",
    "info",
    "warning",
    "danger",
    "default",
    "processing",
  ]),
  message: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.node,
    ]).isRequired,
    summary: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.node,
    ]),
  }).isRequired,
  actions: PropTypes.any,
  isAnimate: PropTypes.bool,
  showBorder: PropTypes.bool,
  closeToast: PropTypes.func,
};

Notification.defaultProps = {
  variant: "info",
  showBorder: false,
};

export const triggerToast = (arg: any) => {
  const { setting = {}, type, ...props } = arg;
  const defaultSettings = {
    position: "top-right",
    theme: "light",
    icon: false,
    hideProgressBar: true,
    pauseOnHover: true,
    autoClose: 3000,
    ...setting,
  };

  return toast(<Notification {...props} />, { ...defaultSettings });
};

triggerToast.propTypes = {
  setting: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.oneOf([
    "success",
    "info",
    "warning",
    "danger",
    "default",
    "processing",
  ]),
  message: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.node,
    ]).isRequired,
    summary: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.node,
    ]),
  }).isRequired,
  actions: PropTypes.any,
  isAnimate: PropTypes.bool,
  showBorder: PropTypes.bool,
  closeToast: PropTypes.func,
};

export default memo(Notification);
