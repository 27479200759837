import { defaultProps, insertOrUpdateBlock } from "@blocknote/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { layoutOptions } from "components/Block/blockMenuList";

export const customBlocksTypes = {
  input: "input",
  number: "number",
  email: "email",
  checkbox: "checkbox",
  textarea: "textarea",
  select: "select",
  radio: "radio",
  datepicker: "datepicker",
  file: "file",
  logic: "logic",
  image: "image",
  sectionStart: "sectionStart",
  sectionEnd: "sectionEnd",
  photoCapture: "photoCapture",
};

export const blocksLabels = {
  inputLabel: "Short text",
  numberLabel: "Number",
  emailLabel: "Email",
  textareaLabel: "Long text",
  checkboxLabel: "Checkbox",
  selectLabel: "Dropdown",
  radioLabel: "Multiple choice",
  datepickerLabel: "Date",
  fileLabel: "File upload",
  logicLabel: "Conditional Logic",
  sectionStartLabel: "Section Group Start",
  sectionEndLabel: "Section Group End",
  photoCaptureLabel: "Photo Capture",
  videoLabel: "Video",
  audioLabel: "Audio",
  othersLabel: "Others",
  paragraphLabel: "Paragraph",
  tableLabel: "Table",
  textLabel: "Text",
  heading3Label: "Heading 3",
};

export const blockGroups = {
  StructureBlocks: "Structure Blocks",
  InputBlocks: "Input Blocks",
  AdvancedBlocks: "Advanced Blocks",
  TextBlocks: "Text Blocks",
  BasicBlocks: "Basic Blocks",
  Headings: "Headings",
};

export const logicSupported = [
  customBlocksTypes.select,
  customBlocksTypes.radio,
];

export const logicOperations = [
  { label: "Show block", value: "show" },
  { label: "Hide block", value: "hide" },
];

export const logicOperators = [
  { label: "Is", value: "is" },
  { label: "Is any of", value: "isAnyof" },
];

export const multiSelectSupportedOperators = ["isAnyof"];

export const blocksSchema = {
  input: {
    required: { default: false },
    disabled: { default: false },
    updatable: { default: true },
    hide: { default: false },
    placeholder: { default: null },
    tooltip: { default: null },
    attrkey: { default: null },
    label: { default: null },
    value: { default: null },
    minLength: { default: null },
    maxLength: { default: null },
    pattern: { default: null },
    layout: { default: layoutOptions[2] },
    rowSpan: { default: null },
    toggleState: {
      default: {
        value: false,
        minLength: false,
        maxLength: false,
        pattern: false,
        placeholder: false,
        rowSpan: false,
      },
    },
  },
  number: {
    required: { default: false },
    disabled: { default: false },
    updatable: { default: true },
    hide: { default: false },
    placeholder: { default: null },
    tooltip: { default: null },
    attrkey: { default: null },
    label: { default: null },
    value: { default: null },
    min: { default: null },
    max: { default: null },
    layout: { default: layoutOptions[2] },
    rowSpan: { default: null },
    toggleState: {
      default: {
        value: false,
        min: false,
        max: false,
        placeholder: false,
        rowSpan: false,
      },
    },
  },
  email: {
    required: { default: false },
    disabled: { default: false },
    updatable: { default: true },
    hide: { default: false },
    placeholder: { default: null },
    tooltip: { default: null },
    attrkey: { default: null },
    label: { default: null },
    value: { default: null },
    layout: { default: layoutOptions[2] },
    rowSpan: { default: null },
    toggleState: {
      default: {
        value: false,
        placeholder: false,
        rowSpan: false,
      },
    },
  },
  checkbox: {
    required: { default: false },
    disabled: { default: false },
    updatable: { default: true },
    hide: { default: false },
    attrkey: { default: null },
    tooltip: { default: null },
    label: { default: null },
    options: {
      default: [
        {
          label: "Option1",
          name: "Option1",
          id: "Option1",
          value: "Option1",
        },
      ],
    },
    layout: { default: layoutOptions[2] },
    rowSpan: { default: null },
    toggleState: {
      default: {
        rowSpan: false,
      },
    },
  },
  textarea: {
    required: { default: false },
    disabled: { default: false },
    updatable: { default: true },
    hide: { default: false },
    placeholder: { default: null },
    tooltip: { default: null },
    attrkey: { default: null },
    label: { default: null },
    value: { default: null },
    minLength: { default: null },
    maxLength: { default: null },
    pattern: { default: null },
    layout: { default: layoutOptions[2] },
    rowSpan: { default: null },
    toggleState: {
      default: {
        value: false,
        minLength: false,
        maxLength: false,
        pattern: false,
        rowSpan: false,
      },
    },
  },
  select: {
    required: { default: false },
    disabled: { default: false },
    hide: { default: false },
    attrkey: { default: null },
    tooltip: { default: null },
    label: { default: null },
    value: { default: null },
    selectFieldType: { default: null },
    selectParentField: { default: null },
    options: {
      default: [
        {
          label: "Option1",
          name: "Option1",
          id: "Option1",
          value: "Option1",
        },
      ],
    },
    layout: { default: layoutOptions[2] },
    rowSpan: { default: null },
    toggleState: {
      default: {
        value: false,
        rowSpan: false,
      },
    },
  },
  radio: {
    required: { default: false },
    disabled: { default: false },
    hide: { default: false },
    attrkey: { default: null },
    tooltip: { default: null },
    label: { default: null },
    value: { default: null },
    options: {
      default: [
        {
          label: "Option1",
          name: "Option1",
          id: "Option1",
          value: "Option1",
        },
      ],
    },
    rowAlign: { default: false },
    layout: { default: layoutOptions[2] },
    rowSpan: { default: null },
    toggleState: {
      default: {
        value: false,
        rowSpan: false,
      },
    },
  },
  datepicker: {
    required: { default: false },
    disabled: { default: false },
    hide: { default: false },
    attrkey: { default: null },
    tooltip: { default: null },
    label: { default: null },
    value: { default: null },
    min: { default: null },
    max: { default: null },
    placeholder: { default: null },
    layout: { default: layoutOptions[2] },
    rowSpan: { default: null },
    toggleState: {
      default: {
        value: false,
        min: false,
        max: false,
        placeholder: false,
        rowSpan: false,
      },
    },
  },
  file: {
    required: { default: false },
    disabled: { default: false },
    updatable: { default: true },
    hide: { default: false },
    attrkey: { default: null },
    tooltip: { default: null },
    label: { default: null },
    fileTypes: { default: [] },
    size: { default: null },
    faceAuth: { default: false },
    faceDetect: { default: false },
    preview: { default: false },
    layout: { default: layoutOptions[2] },
    rowSpan: { default: null },
    toggleState: {
      default: {
        fileTypes: false,
        size: false,
        rowSpan: false,
      },
    },
  },
  photoCapture: {
    attrkey: { default: null },
    required: { default: false },
    faceDetect: { default: false },
    layout: { default: layoutOptions[2] },
    rowSpan: { default: null },
    toggleState: {
      default: {
        rowSpan: false,
      },
    },
  },
  logic: {
    actionBlock: { default: null },
    actionBlockOptions: { default: null },
    targetBlocks: { default: null },
    operator: { default: { label: "Is", value: "is" } },
    operation: { default: { label: "Hide block", value: "hide" } },
  },
  sectionStart: {
    label: { default: null },
    ...defaultProps,
    border: { default: false },
  },
  sectionEnd: {},
};

export const insertBlock = (
  editor: any,
  type: string,
  title: string,
  icon?: any,
  group?: string,
) => ({
  title,
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      type,
    });
  },
  icon: <FontAwesomeIcon icon={icon} size="lg" />,
  group: group || blockGroups.InputBlocks,
});
