import CheckboxTree from "components/Tools/Checkbox/CheckboxTree";
import DatePicker from "components/Tools/DatePicker";
import Input from "components/Tools/Input";
import RadioButtonGroup from "components/Tools/RadioButtonGroup";
import Select from "components/Tools/Select";
import Text from "components/Tools/Text";
import TextArea from "components/Tools/TextArea";
import File from "components/Tools/File";
import Button from "components/Button";
import Image from "components/Tools/Image";
import PhotoCapture from "components/Tools/PhotoCapture";
import SectionGroup from "components/Tools/SectionGroup";
import { customBlocksTypes } from "components/Tools/CustomBlocks/utils";

export const getElement = (type: string, id?: string) => {
  switch (type) {
    case customBlocksTypes.input:
      return <Input type="text" labelSize="sm" />;
    case customBlocksTypes.number:
      return <Input type="number" labelSize="sm" />;
    case customBlocksTypes.email:
      return <Input type="email" labelSize="sm" />;
    case customBlocksTypes.checkbox:
      return <CheckboxTree block_id={id} />;
    case customBlocksTypes.radio:
      return <RadioButtonGroup />;
    case "text":
      return <Text />;
    case customBlocksTypes.datepicker:
      return <DatePicker />;
    case customBlocksTypes.select:
      return (
        <Select
          labelKey="label"
          primaryKey="value"
          isDisabled
          isClearable
          isSearchable
        />
      );
    case customBlocksTypes.textarea:
      return <TextArea labelSize="sm" />;
    case customBlocksTypes.file:
      return <File />;
    case customBlocksTypes.image:
      return <Image />;
    case customBlocksTypes.photoCapture:
      return <PhotoCapture />;
    case customBlocksTypes.sectionStart:
      return <SectionGroup />;
    case customBlocksTypes.sectionEnd:
      return <SectionGroup type={customBlocksTypes.sectionEnd} />;
    case "button":
      return <Button />;
    default:
      return <></>;
  }
};
