import CreateBlock from "components/Tools/CustomBlocks/CreateBlock";
import {
  blocksSchema,
  customBlocksTypes,
} from "components/Tools/CustomBlocks/utils";

export const InputTextBlock = CreateBlock({
  propSchema: blocksSchema.input,
  type: customBlocksTypes.input,
});

export const NumberBlock = CreateBlock({
  propSchema: blocksSchema.number,
  type: customBlocksTypes.number,
});

export const EmailBlock = CreateBlock({
  propSchema: blocksSchema.email,
  type: customBlocksTypes.email,
});

export const CheckBoxBlock = CreateBlock({
  propSchema: blocksSchema.checkbox,
  type: customBlocksTypes.checkbox,
});

export const TextAreaBlock = CreateBlock({
  propSchema: blocksSchema.textarea,
  type: customBlocksTypes.textarea,
});

export const DropDownBlock = CreateBlock({
  propSchema: blocksSchema.select,
  type: customBlocksTypes.select,
});

export const RadioBlock = CreateBlock({
  propSchema: blocksSchema.radio,
  type: customBlocksTypes.radio,
});

export const DatePickerBlock = CreateBlock({
  propSchema: blocksSchema.datepicker,
  type: customBlocksTypes.datepicker,
});

export const FileUploadBlock = CreateBlock({
  propSchema: blocksSchema.file,
  type: customBlocksTypes.file,
});

export const LogicBlock = CreateBlock({
  propSchema: blocksSchema.logic,
  type: customBlocksTypes.logic,
});

export const PhotoCaptureBlock = CreateBlock({
  propSchema: blocksSchema.photoCapture,
  type: customBlocksTypes.photoCapture,
});

export const SectionStartBlock = CreateBlock({
  propSchema: blocksSchema.sectionStart,
  type: customBlocksTypes.sectionStart,
});

export const SectionEndBlock = CreateBlock({
  propSchema: blocksSchema.sectionEnd,
  type: customBlocksTypes.sectionEnd,
});
