import React, {
  ReactNode,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import { get } from "lodash";
import * as sentry from "@sentry/react";
import {
  useExchangeTokenMutation,
  useGetAccessTokenMutation,
  useGetCustomTokenMutation,
} from "store/services/authService";
import { processToken } from "utils";
import Loader from "components/Loader";

const Auth: React.FC<{ children: ReactNode }> = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("accessToken") || "";
  const hashToken = urlParams.get("c") || urlParams.get("hash") || "";
  const [exchangeToken] = useExchangeTokenMutation();
  const [getCustomToken] = useGetCustomTokenMutation();
  const [getAccessToken] = useGetAccessTokenMutation();
  const isAuthInProgress = useRef(false);

  const [isAuthComplete, setIsAuthComplete] = useState(false);

  const onAuthSuccess = () => setIsAuthComplete(true);

  const onAuthFailure = (error: any) => {
    setIsAuthComplete(false);
    sentry.captureException(error);
  };

  const fetchAccessToken = useCallback(
    async ({
      customToken,
      token,
    }: {
      customToken?: string;
      token?: string;
    }) => {
      let accessToken;
      if (token) {
        const res = await exchangeToken({ token }).unwrap();
        accessToken = get(res, "auth_get_exchange_token.access_token");
      } else if (customToken) {
        const res = await getAccessToken({ customToken }).unwrap();
        accessToken = get(res, "auth_get_access_token.data.access_token");
      }

      if (accessToken) {
        processToken(accessToken);
        onAuthSuccess();
      } else onAuthFailure(new Error("Failed to fetch access token"));
    },
    [],
  );

  useLayoutEffect(() => {
    (async () => {
      if (isAuthComplete || isAuthInProgress.current) return;
      isAuthInProgress.current = true;
      try {
        if (token) {
          const { tenant_id } = processToken(token) || {};
          if (!tenant_id) await fetchAccessToken({ token });
          else onAuthSuccess();
        } else if (hashToken) {
          const res = await getCustomToken({ hashToken }).unwrap();
          const customToken = get(res, "auth_get_custom_token.custom_token");
          if (customToken) await fetchAccessToken({ customToken });
          else onAuthFailure(new Error("Failed to fetch custom token"));
        } else {
          onAuthFailure(new Error("No Token Found"));
        }
      } catch (e) {
        onAuthFailure(e);
      }
    })();
  }, []);

  return isAuthComplete ? (
    <div>{children}</div>
  ) : (
    <div className="min-h-screen flex items-center justify-center">
      <Loader />
    </div>
  );
};

export default Auth;
